import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchItemById, addItem, updateItem, getCategories,deleteImageById } from '../CloudService'; // Ensure this import path is correct
import { v4 as uuidv4 } from 'uuid';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Import Firebase storage functions
import './AddEditItem.css'; // Import the CSS file for styling

const AddEditItem = () => {
  const [item, setItem] = useState(null);
  const [name, setName] = useState('');
  const [image, setImage] = useState(null);
  const [currentImage, setCurrentImage] = useState(null); // Change to null instead of ''
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [category, setCategory] = useState('');
  const [categories, setCategories] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch item data if editing
    if (id) {

      const getItem = async () => {
        const itemData = await fetchItemById(id);
        setItem(itemData);
        setName(itemData.name);
        setCurrentImage(itemData.image); // Assume this is a URL
        setDescription(itemData.description);
        setPrice(itemData.price);
        setCategory(itemData.category);
      };

      getItem();
    }
  }, [id]);

  useEffect(() => {
    // Fetch categories
    const fetchCategories = async () => {
      try {
        const categoriesData = await getCategories();
        setCategories(categoriesData);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);
  
  const handleImageUpload = async (file) => {
    const storage = getStorage();
    const storageRef = ref(storage, `items/${file.name}`);
    
    // Upload the file to the specified reference
    await uploadBytes(storageRef, file);
    
    // Get the download URL for the uploaded file
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   let imageUrl = '';

  //   // If a new image has been uploaded, handle the upload
  //   if (image) {
  //     imageUrl = await handleImageUpload(image); // Upload image and get URL
  //   } else {
  //     imageUrl = item?.image; // If editing, keep the existing image URL
  //   }

  //   // Prepare the data object without the File object
  //   var  updatedItem ;
  //   !image?
  //    updatedItem = {
  //     name, // This should be a URL string, not a File object
  //     description,
  //     price,
  //     category
  //   }:updatedItem = {
  //     name,
  //     image: imageUrl,
  //     description,
  //     price,
  //     category
  //   };

    
    
  //   if (id) {
  //     await updateItem(id, updatedItem); // Pass the updated item object
  //   } else {
  //     const newId = uuidv4(); // Use a new ID for the new item
  //     await addItem({ id: newId, name, image, description, price, category });
  //   }
  //   navigate('/admin');
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let imageUrl = '';
  
    // If a new image has been uploaded, handle the upload and delete the old one
    if (image) {
      imageUrl = await handleImageUpload(image); // Upload new image and get URL
  
      // If the item already exists and there's a current image, delete the old image
      if (currentImage && currentImage !== imageUrl) {
        const oldImagePath = currentImage.split('?')[0]; // Get the image path from the URL
        const imageId = oldImagePath.substring(oldImagePath.indexOf('/items/')); // Extract the Firebase Storage path
        await deleteImageById(imageId); // Delete the old image from Firebase Storage
      }
    } else {
      imageUrl = item?.image; // If no new image is uploaded, keep the existing image URL
    }
  
    // Prepare the updated item data
    const updatedItem = {
      name,
      image: imageUrl,
      description,
      price,
      category
    };
  
    if (id) {
      await updateItem(id, updatedItem); // Update the item
    } else {
// alert(imageUrl);
      const newId = uuidv4(); // Generate a new ID for the new item
      await addItem({ id: newId, name, image: imageUrl, description, price, category });
    }
  
    navigate('/admin'); // Redirect back to the admin page
  };
  
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file); // Store the File object
    }
  };

  return (
    <div className="add-edit-item-container">
      <h2>{id ? 'Edit Item' : 'Add Item'}</h2>
      <form onSubmit={handleSubmit} className="add-edit-item-form">
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            id="name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Name"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="image">Upload Image</label>
          <input
            id="image"
            type="file"
            accept="image/*"
            onChange={handleFileChange}
          />
          {item && currentImage !== null && ( // Show existing image if editing and no new image is selected
            <div>
              <img src={currentImage} alt="Current Upload" style={{ width: '100px', height: 'auto', marginTop: '10px' }} />
              <p>Current Image</p>
            </div>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="description">Description</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Description"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="price">Price</label>
          <input
            id="price"
            type="text"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            placeholder="Price"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="category">Category</label>
          <select
            id="category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            required
          >
            <option value="" disabled>Select Category</option>
            {categories.map(cat => (
              <option key={cat.id} value={cat.name}>{cat.name}</option>
            ))}
          </select>
        </div>
        <button type="submit" className="submit-button">
          {id ? 'Update' : 'Add'} Item
        </button>
      </form>
    </div>
  );
};

export default AddEditItem;