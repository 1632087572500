import React from 'react';
import './Cart.css'; // Import your CSS for styling

const Cart = ({ cartItems, removeItemFromCart, totalPrice }) => {
  const handleOrderNow = () => {
    const cartDetails = cartItems.map(item => `Item Name: ${item.name}\nItem Price: ${item.price} JOD\nItem Image: ${item.image}`).join('\n\n');
    const whatsappMessage = `Order Details:\n${cartDetails}`;
    const encodedMessage = encodeURIComponent(whatsappMessage);
    const whatsappUrl = `https://wa.me/+962785480490?text=${encodedMessage}`;
    
    window.open(whatsappUrl, '_blank');
  };

  return (
    <div className="cart-container">
      <h2>Your Cart</h2>
      <ul className="cart-item-list">
        {cartItems.length === 0 ? (
          <li className="empty-cart">Your cart is empty.</li>
        ) : (
          cartItems.map(item => (
            <li key={item.id} className="cart-item">
              <div className="item-details">
                <img src={item.image} alt={item.name} className="item-image" />
                <div className="item-info">
                  <h3>{item.name}</h3>
                  <p>JOD {item.price}</p>
                </div>
              </div>
              <button className="remove-button" onClick={() => removeItemFromCart(item.id)}>Remove</button>
            </li>
          ))
        )}
      </ul>
      <h3 className="total-price">Total: JOD {totalPrice}</h3>
      <button onClick={handleOrderNow} className="order-button">Order Now</button>
    </div>
  );
};

export default Cart;
