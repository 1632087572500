import React, { useState } from 'react';
import { Link,useParams } from 'react-router-dom';
import { fetchItems, deleteItem,getCategories,getCategoryById,deleteCategory } from '../CloudService';
import AddEditCategory from '../AddEditCategory/AddEditCategory';  // Import the category component
import AddEditItem from '../AddEditItem/AddEditItem';  // Import the item component
import AdminHeader from '../AdminHeader/AdminHeader'
import './AdminDashboard.css'; // Import the CSS file for styling

const AdminDashboard = () => {
  const [items, setItems] = React.useState([]);
  
  const [categories, setCategories] = useState([]);
  const [categoryName, setCategoryName] = useState('');
  const [categoryImage, setCategoryImage] = useState(null);
  const { id } = useParams(); // Get the category ID from the URL parameters
  
  const [searchQuery, setSearchQuery] = React.useState('');
  const [activeComponent, setActiveComponent] = useState('');  // Manage active component state

  // Fetch items on component mount
  React.useEffect(() => {
    const getItems = async () => {
      const data = await fetchItems();
      setItems(data);
    };
    getItems();
  }, []);

  React.useEffect((id) => {
    const fetchCategories = async () => {
      const fetchedCategories = await getCategories();
      setCategories(fetchedCategories);
    };

    fetchCategories();

    if (id) {
      const fetchCategory = async () => {
        const category = await getCategoryById(id);
        if (category) {
          setCategoryName(category.name);
          // Assuming category has an image field
          setCategoryImage(category.image); 
        }
      };
      fetchCategory();
    }
  }, [id]); 

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this item?');
    if (confirmDelete) {
      await deleteItem(id);
      setItems(items.filter(item => item.id !== id));
    }
  };
  const handleDeleteCat = async (categoryId) => {
    if (window.confirm('Are you sure you want to delete this category?')) {
      await deleteCategory(categoryId);
      // Refresh the categories list after deletion
      const updatedCategories = await getCategories();
      setCategories(updatedCategories);
    }
  };

  const filteredItems = items.filter((item,index) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase()),
    
  );
  const filteredCategories = categories.filter(category =>
    category.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Render the selected component based on sidebar click
  const renderComponent = () => {
    switch (activeComponent) {
      case 'category':
        return <div className="admin-dashboard-container">
            <h1>Manage Category</h1>
            <Link to="/admin/addCategory" className="add-new-item-link">Add New Category</Link>
            <div className="search-container">
              <input
                type="text"
                placeholder="Search items..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="search-input"
              />
            </div>
            <table className="item-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Image</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
              {filteredCategories.map(category => (
                  <tr key={category.id}>
                    <td>{category.name}</td>
                    <td>
                <img src={category.image} alt={category.name} style={{ width: '50px', height: '50px' }} />
              </td>
                    <td>
                      <Link to={`/admin/editCategory/${category.id}`} className="edit-link">Edit</Link>
                      <button onClick={() => handleDeleteCat(category.id)} className="delete-button">Delete</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
      case 'item':
        return (
          <div className="admin-dashboard-container">
            <h1>Manage Items</h1>
            <Link to="/admin/add" className="add-new-item-link">Add New Item</Link>
            <div className="search-container">
              <input
                type="text"
                placeholder="Search items..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="search-input"
              />
            </div>
            <table className="item-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Price</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredItems.map(item => (
                  <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>{item.price}</td>
                    <td>
                      <Link to={`/admin/edit/${item.id}`} className="edit-link">Edit</Link>
                      <button onClick={() => handleDelete(item.id)} className="delete-button">Delete</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
      default:
        return <div>Select an option from the menu to manage categories or items.</div>;
    }
  };

  return (
    <div className="admin-dashboard">
      
      {/* Sidebar */}
      <div className="admin-sidebar">
        <ul>
          <li onClick={() => setActiveComponent('category')}>Manage Categories</li>
          <li onClick={() => setActiveComponent('item')}>Manage Items</li>
        </ul>
      </div>
      
      {/* Content Area */}
      <div className="admin-content">
        {renderComponent()}
      </div>
    </div>
  );
};

export default AdminDashboard;
