// // src/components/Filter/Filter.js
// import React from 'react';
// import './Filter.css'; // Ensure you have corresponding CSS for styling
// import ChildIcon from '../../Assist/child.png';
// import WomenIcon from '../../Assist/women.png';
// import MenIcon from '../../Assist/men.png';

// const Filter = ({ onFilterChange }) => {
//   return (
//     <div className="filter">
//       <div className="filter-item" onClick={() => onFilterChange('Men')}>
//         <img src={MenIcon} alt="Men" />
//         <p>Men</p>
//       </div>
//       <div className="filter-item" onClick={() => onFilterChange('Women')}>
//         <img src={WomenIcon} alt="Women" />
//         <p>Women</p>
//       </div>
//       <div className="filter-item" onClick={() => onFilterChange('Child')}>
//         <img src={ChildIcon} alt="Child" />
//         <p>Child</p>
//       </div>
//     </div>
//   );
// };

// export default Filter;
// src/components/Filter/Filter.js
import React, { useState } from 'react';
import './Filter.css'; // Ensure you have corresponding CSS for styling
import ChildIcon from '../../Assist/child.png';
import WomenIcon from '../../Assist/women.png';
import MenIcon from '../../Assist/men.png';
import { fetchItems, deleteItem,getCategories,getCategoryById,deleteCategory } from '../Admin/CloudService';


const FilterCategory = [
  {
    id: 1,
    name: "Men",
    icon: MenIcon
  },
  {
    id: 2,
    name: "Women",
    icon: WomenIcon
  },
  {
    id: 3,
    name: "Kids",
    icon: ChildIcon
  },
  {
    id: 4,
    name: "Electronics",
    icon: MenIcon // Placeholder, replace as needed
  },
  {
    id: 5,
    name: "Home & Garden",
    icon: WomenIcon // Placeholder, replace as needed
  },
  {
    id: 6,
    name: "Beauty & Health",
    icon: ChildIcon // Placeholder, replace as needed
  },
  {
    id: 7,
    name: "Sports & Outdoors",
    icon: MenIcon // Placeholder, replace as needed
  },
  {
    id: 8,
    name: "Toys",
    icon: WomenIcon // Placeholder, replace as needed
  },
  {
    id: 9,
    name: "Books",
    icon: ChildIcon // Placeholder, replace as needed
  },
  {
    id: 10,
    name: "Automotive",
    icon: MenIcon // Placeholder, replace as needed
  },
  {
    id: 11,
    name: "Jewelry",
    icon: WomenIcon // Placeholder, replace as needed
  },
  {
    id: 12,
    name: "Pet Supplies",
    icon: ChildIcon // Placeholder, replace as needed
  },
  {
    id: 13,
    name: "Food & Beverages",
    icon: MenIcon // Placeholder, replace as needed
  },
  {
    id: 14,
    name: "Office Supplies",
    icon: WomenIcon // Placeholder, replace as needed
  },
  {
    id: 15,
    name: "Gift Cards",
    icon: ChildIcon // Placeholder, replace as needed
  }
];


const Filter = ({ onFilterChange }) => {
  const [categories, setCategories] = useState([]);
const [categoryName, setCategoryName] = useState('');
const [categoryImage, setCategoryImage] = useState(null)

React.useEffect(() => {
  const fetchCategories = async () => {
    const fetchedCategories = await getCategories();
    setCategories(fetchedCategories);
  };

  fetchCategories();

  
}, []); 

  return (
    <div className="filter">
      {
        categories.map(cate=>(
          <div key={cate.id} className="filter-item" onClick={() => onFilterChange(cate.name)}>
          <img src={cate.image} alt={cate.name} />
          <p>{cate.name}</p>
        </div>
        ))
      }
     
      {/* <div className="filter-item" onClick={() => onFilterChange('Women')}>
        <img src={WomenIcon} alt="Women" />
        <p>Women</p>
      </div>
      <div className="filter-item" onClick={() => onFilterChange('Child')}>
        <img src={ChildIcon} alt="Child" />
        <p>Child</p>
      </div> */}
    </div>
  );
};

export default Filter;
