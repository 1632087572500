// src/components/ItemGrid.js
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Filter from '../Filter/Filter';
import './ItemGrid.css';
import { fetchItems } from '../Admin/CloudService'; // Ensure this path is correct

Modal.setAppElement('#root'); // Important for accessibility

const ItemGrid = ({ searchQuery, onAddToCart }) => {
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [filter, setFilter] = useState('All');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getItems = async () => {
      try {
        const data = await fetchItems();
        setItems(data);
      } catch (error) {
        setError('Failed to load items.');
      } finally {
        setLoading(false);
      }
    };

    getItems();
  }, []);

  const safeSearchQuery = (searchQuery || '').toLowerCase();

  const filteredItems = items
    .filter(item => (filter === 'All' || item.category === filter))
    .filter(item => item.name?.toLowerCase().includes(safeSearchQuery));

  useEffect(() => {
    if (searchQuery) {
      setFilter('All');
    }
  }, [searchQuery]);

  // Helper function for WhatsApp link
  const generateWhatsAppLink = (item) =>
    `https://wa.me/+962785480490?text=I%20want%20to%20order%20${encodeURIComponent(item.name)}%description%20${encodeURIComponent(item.description)}%20image:%20${encodeURIComponent(item.image)}%20price:%20${encodeURIComponent(item.price)}`;

  // Handle closing modal with Esc key
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setSelectedItem(null); // Close modal on 'Esc'
      }
    };
    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  const handleItemClick = (item) => {
    setSelectedItem(item);
    console.log('Selected Item:', item); // Check if item is set
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  const { name, description, image, price } = selectedItem || {};

  return (
    <div>
      <div style={{ marginTop: 20 }}>
        <Filter onFilterChange={(selectedFilter) => setFilter(selectedFilter)} />
      </div>
      <div className="item-grid">
        {filteredItems.map(item => (
          <div onClick={() => setSelectedItem(item)}>
          <div key={item.id} className="item"
          style={{
            backgroundImage: `url(${item.image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}>
            
            
           
           
            
            <a  target="_blank" rel="noopener noreferrer">
              <button onClick={() => onAddToCart(item)}>Add to cart</button>
            </a>
          </div>
          <div className="item-content">
              <h3>{item.name}</h3>
              {/* <h5>{item.description}</h5> */}
              <p>JOD {item.price}</p>
            </div>
          </div>
        ))}
      </div>
      {/* <div className="item-grid">
        {filteredItems.map(item => (
          <div
            key={item.id}
            className="item"
            style={{
              backgroundImage: `url(${item.image})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
            onClick={() => handleItemClick(item)}
          >
            <div className="item-content">
              <h3>{item.name}</h3>
              <h5>{item.description}</h5>
              <p>JOD {item.price}</p>
            </div>
            <button onClick={() => onAddToCart(item)} >Add to cart</button>
          </div>
        ))}
      </div> */}
      

      {/* <Modal
        isOpen={!!selectedItem}
        onRequestClose={() => setSelectedItem(null)}
        contentLabel="Item Details"
        className="modal"
        overlayClassName="overlay"
      >
     
        {selectedItem && (
          <>
            <button className="close-button" onClick={() => setSelectedItem(null)}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 8.586L15.95 2.636a1 1 0 011.414 1.414L11.414 10l5.95 5.95a1 1 0 01-1.414 1.414L10 11.414l-5.95 5.95a1 1 0 01-1.414-1.414L8.586 10 2.636 4.05a1 1 0 011.414-1.414L10 8.586z" clipRule="evenodd" />
              </svg>
            </button>
            <img src={image} alt={name} />
            <h2>{name}</h2>
            <p>{description}</p>
            <strong>JOD{price}</strong>
            <a target="_blank" rel="noopener noreferrer">
              <button onClick={() => onAddToCart(selectedItem)}>Add to cart</button>
            </a>
          </>
        )}
      </Modal> */}
     <Modal
  isOpen={!!selectedItem}
  onRequestClose={() => setSelectedItem(null)}
  contentLabel="Item Details"
  className="modal"
  overlayClassName="overlay"
>
  {selectedItem && (
    <>
      <button className="close-button" onClick={() => setSelectedItem(null)}>
        {/* SVG Icon for Close Button */}
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M10 8.586L15.95 2.636a1 1 0 011.414 1.414L11.414 10l5.95 5.95a1 1 0 01-1.414 1.414L10 11.414l-5.95 5.95a1 1 0 01-1.414-1.414L8.586 10 2.636 4.05a1 1 0 011.414-1.414L10 8.586z" clipRule="evenodd" />
        </svg>
      </button>

      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch', height: '100%' }}>
        {/* Left side for the image */}
        <div
          style={{
            backgroundImage: `url(${selectedItem.image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundColor: '#f0f0f0', // Fallback color
            width: '40%', // Adjust width as needed
            minHeight: '300px', // Ensures the image container has enough height
          }}
        />

        {/* Right side for the details */}
        <div style={{ padding: '20px', width: '60%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <h2>{selectedItem.name}</h2>
          <p>{selectedItem.description}</p>
          <strong>JOD{selectedItem.price}</strong>
          <a target="_blank" rel="noopener noreferrer">
            <button onClick={() => onAddToCart(selectedItem)}>Add to cart</button>
          </a>
        </div>
      </div>
    </>
  )}
</Modal>

    </div>
  );
};

export default ItemGrid;
