// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';

const ProtectedRoute = ({ children }) => {
  const auth = getAuth();
  const user = auth.currentUser;

  // If the user is not authenticated, redirect to login
  if (!user) {
    return <Navigate to="/admin/login" />;
  }

  // If the user is authenticated, render the children (the protected component)
  return children;
};

export default ProtectedRoute;
