import { db } from '../../firebase/firebaseConfig'; // Ensure Firebase is properly initialized
import { collection, addDoc, updateDoc, doc, getDoc, deleteDoc, getDocs } from 'firebase/firestore';
import { storage } from '../../firebase/firebaseConfig'; // Import your Firebase storage config
import { ref, uploadBytes, getDownloadURL,deleteObject } from 'firebase/storage';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
const itemCollectionRef = collection(db, 'items');
const categoryCollectionRef = collection(db, 'categories'); // Reference for categories




export const fetchItems = async () => {
    try {
        const snapshot = await getDocs(itemCollectionRef);
        return snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
    } catch (error) {
        console.error('Error fetching items from Firestore:', error);
        return [];
    }
};

export const fetchItemById = async (id) => {
    const docRef = doc(db, 'items', id);
    const docSnap = await getDoc(docRef);
    return docSnap.exists() ? docSnap.data() : null;
};

export const addItem = async (item) => {
    // await addDoc(itemCollectionRef, item);
    // const imageUrl = await uploadImageItem(item.image); // Upload image and get URL
//    alert(imageUrl);
    // item.image = imageUrl;
    await addDoc(itemCollectionRef, item);
};


export const updateItem = async (id, item) => {
    // alert(JSON.stringify(item))\

    if(id){
        const docRef = doc(db, 'items', id);
        await updateDoc(docRef, item);
    }
    
    
};

export const deleteItem = async (id) => {
    const docRef = doc(db, 'items', id);
    await deleteDoc(docRef);
};
export const uploadImageItem = async (file) => {
    const storageRef = ref(storage, `items/${file.name}`);
    await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);
    return url;
  };
// export const uploadImageItem = async (file) => {
//     try {
//       if (!file) {
//         throw new Error('No file provided for upload');
//       }
  
//       const storageRef = ref(storage, `items/${file.name}`); // Create reference in storage
//       await uploadBytes(storageRef, file); // Upload the file
//       const url = await getDownloadURL(storageRef); // Get the download URL for the uploaded file
//       return url; // Return the download URL
//     } catch (error) {
//       console.error('Error uploading image:', error);
//       return null; // Return null if an error occurs
//     }
//   };
  export const deleteImageById = async (imageId) => {
    try {
        // Get the reference to the file using the imageId or path
        const imageRef = ref(storage, imageId);
        // Delete the image from Firebase Storage
        await deleteObject(imageRef);
        console.log(`Image deleted successfully: ${imageId}`);
    } catch (error) {
        console.error('Error deleting image:', error);
    }
};
export const uploadImage = async (file) => {
  const storageRef = ref(storage, `categories/${file.name}`);
  await uploadBytes(storageRef, file);
  const url = await getDownloadURL(storageRef);
  return url;
};
export const addCategory = async (category) => {
  const imageUrl = await uploadImage(category.image); // Upload image and get URL
  await addDoc(categoryCollectionRef, { name: category.name, image: imageUrl });
};
// Function to update a category
export const updateCategory = async (id, updatedCategory) => {
  let imageUrl = updatedCategory.image;
  if (typeof imageUrl === 'object') { // Check if it's a file
      imageUrl = await uploadImage(imageUrl); // Upload image if it's a new file
  }
  const categoryRef = doc(db, 'categories', id);
  await updateDoc(categoryRef, { name: updatedCategory.name, image: imageUrl });
};
// Function to get all categories
export const getCategories = async () => {
    try {
        const querySnapshot = await getDocs(collection(db, 'categories')); // Get all categories
        const categories = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        return categories;
    } catch (error) {
        console.error('Error getting categories:', error);
        return [];
    }
};

// Function to update a category
// export const updateCategory = async (id, updatedCategory) => {
//     try {
//         const categoryRef = doc(db, 'categories', id); // Reference to the specific category document
//         await updateDoc(categoryRef, updatedCategory); // Update the category document
//     } catch (error) {
//         console.error('Error updating category:', error);
//     }
// };

// Function to delete a category
export const deleteCategory = async (id) => {
  try {
      const categoryRef = doc(db, 'categories', id); // Reference to the specific category document
      await deleteDoc(categoryRef); // Delete the category document
  } catch (error) {
      console.error('Error deleting category:', error);
  }
};

// Function to get a category by ID
export const getCategoryById = async (id) => {
    try {
        const categoryRef = doc(db, 'categories', id); // Reference to the specific category document
        const categoryDoc = await getDoc(categoryRef); // Get the category document
        if (categoryDoc.exists()) {
            return { id: categoryDoc.id, ...categoryDoc.data() };
        } else {
            console.log('No such category!');
            return null;
        }
    } catch (error) {
        console.error('Error getting category:', error);
        return null;
    }
};

// // Function to add a new category
// export const addCategory = async (category) => {
//     try {
//         const categoryCollectionRef = collection(db, 'categories'); // Reference to the categories collection
//         await addDoc(categoryCollectionRef, category); // Add the new category document
//     } catch (error) {
//         console.error('Error adding category:', error);
//     }
// };
export const login = async (email, password) => {
    const auth = getAuth();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      return userCredential.user;
    } catch (error) {
      throw new Error(error.message);
    }
  };