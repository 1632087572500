import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getCategoryById, addCategory, updateCategory } from '../CloudService'; // Ensure this import path is correct
import './AddEditCategory.css'; // Import the CSS file for styling


const AddEditCategory = () => {
  const [item, setItem] = useState(null);
  const [name, setName] = useState('');
  const [image, setImage] = useState('');
  
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      const getItem = async () => {
        const itemData = await getCategoryById(id);
        setItem(itemData);
        setName(itemData.name);
        setImage(itemData.image);
      };

      getItem();
    }
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (id) {
      await updateCategory(id, { name, image });
    } else {
      await addCategory({ name, image });
    }
    navigate('/admin');
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  

  return (
    <div className="add-edit-item-container">
      <h2>{id ? 'Edit Item' : 'Add Item'}</h2>
      <form onSubmit={handleSubmit} className="add-edit-item-form">
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            id="name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Name"
            required
          />
        </div>
        <div className="form-group">
          {/* <label htmlFor="image">Image URL</label>
          <input
            id="image"
            type="text"
            value={image}
            onChange={(e) => setImage(e.target.value)}
            placeholder="Image URL"
            required
          /> */}
           <label htmlFor="categoryImage">Upload Image</label>
          <input
            id="categoryImage"
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            required
          />
        </div>
        
        <button type="submit" className="submit-button">
          {id ? 'Update' : 'Add'} Item
        </button>
      </form>
    </div>
  );
};

export default AddEditCategory;
