import React from 'react';
import './AdminHeader.css'; 
import Logo from '../../../Assist/logo.png';


const Header = () => {
  return (
    <header className="admin_header">
      <div className="admin_logo">
        <img src={Logo} alt="Company Logo" />
      </div>
    </header>
  );
};

export default Header;
