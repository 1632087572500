// src/firebase/firebaseConfig.js
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage'; // If using Firebase Storage

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCE1gSTJDRIyDi8pheFIORoDK9yGttvMAw",
  authDomain: "seen-65473.firebaseapp.com",
  projectId: "seen-65473",
  storageBucket: "seen-65473.appspot.com",
  messagingSenderId: "924813974369",
  appId: "1:924813974369:web:327e94125767014ebc4320",
  measurementId: "G-LQFN33Y3RD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore and other services
const db = getFirestore(app);
const storage = getStorage(app); // If using Firebase Storage

// Export the initialized services
export { db, storage };
